import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import "./App.scss";

import Navbar from "./components/navbar";
import Footer from "./components/footer";

import Home from "./screens/home";
import Category from "./screens/category";
import Blogs from "./screens/blogs";

export default class App extends Component {
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Navbar></Navbar>
          <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route exact path="/:category" element={<Category />}></Route>
            <Route exact path="/:category/:slug?" element={<Blogs />}></Route>
          </Routes>
          <Footer></Footer>
        </BrowserRouter>
      </div>
    );
  }
}
