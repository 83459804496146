import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faXTwitter,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import logo from "../assets/logo.png";

import "./footer.scss";

export class Footer extends Component {
  constructor() {
    super();

    this.state = {
      category: "",
    };
  }

  render() {
    return (
      <div className="footer">
        <div className="container">
          <div className="row pt-5">
            <div className="col-xl-3 col-6">
              <img src={logo} alt="logo" width="150px" className="mb-5" />

              <div className="follow">
                <div className="widget-title">
                  <div className="subtitle">Follow us</div>
                </div>
                <FontAwesomeIcon
                  className="icon"
                  icon={faFacebook}
                  onClick={() =>
                    (window.location.href = "https://facebook.com/tezzfeed")
                  }
                  size="2x"
                ></FontAwesomeIcon>
                <FontAwesomeIcon
                  className="icon"
                  icon={faInstagram}
                  size="2x"
                ></FontAwesomeIcon>
                <FontAwesomeIcon
                  className="icon"
                  icon={faLinkedin}
                  size="2x"
                ></FontAwesomeIcon>
                <FontAwesomeIcon
                  className="icon"
                  icon={faXTwitter}
                  size="2x"
                ></FontAwesomeIcon>
                <FontAwesomeIcon
                  className="icon"
                  icon={faYoutube}
                  size="2x"
                ></FontAwesomeIcon>
              </div>
            </div>
            <div className="col-xl-5 col-sm-6">
              <div className="widget-title">
                <div className="subtitle">About us</div>
              </div>
              <p>
                TezzFeed is a community of creators driven news & entertainment
                network. Our goal is to connect creators with the right audience
                and the audience with content they love more.
              </p>
            </div>
            <div className="col-xl-4 col-sm-6">
              <div className="widget-title">
                <div className="subtitle">Categories</div>
              </div>
              <select
                onChange={(event) =>
                  this.setState({ category: event.target.value }, () =>
                    this.state.category === 0
                      ? null
                      : (window.location.href = `${this.state.category}`)
                  )
                }
                className="form-control"
              >
                <option value={0}>Select Category</option>
                <option value="opinion">Opinion</option>
                <option value="sports">Sports</option>
                <option value="business">Business</option>
                <option value="entertainment">Entertainment</option>
                <option value="life-living">Life & Living</option>
                <option value="youth">Youth</option>
                <option value="editors-pick">Editor's Pick</option>
                <option value="tech-startup">Tech & Startup</option>
                <option value="movies-series">Movies & Series</option>
                <option value="medical-news">Medical News</option>
                <option value="tragedy-news">Tragedy News</option>
                <option value="gaming-news">Gaming News</option>
                <option value="multimedia">Multimedia</option>
                <option value="finance">Finance</option>
                <option value="latest-news">Latest News</option>
                <option value="feature-news">Feature News</option>
                <option value="international-news">International News</option>
                <option value="bangladeshi-news">Bangladeshi News</option>
                <option value="breaking-news">Breaking News</option>
                <option value="law-order">Law & Order</option>
                <option value="environment">Environment</option>
              </select>
            </div>
          </div>

          <div className="mt-3 pb-2 pt-4 copyright text-center border-top">
            <p>Copyright &copy; {new Date().getFullYear()} Tezzfeed</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
