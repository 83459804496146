import React, { Component } from "react";
import axios from "axios";
import dateFormat from "dateformat";
import { Helmet } from "react-helmet";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";

import Spinner from "../components/spinner";

import { withRouter } from "../controllers/withRouter";

import "./blogs.scss";

import { FaFacebook, FaXTwitter, FaLinkedin } from "react-icons/fa6";

let categories = [
  { value: "latest-news", label: "Latest News" },
  { value: "editors-pick", label: "Editor's Pick" },
  { value: "opinion", label: "Opinion" },
  { value: "sports", label: "Sports" },
  { value: "business", label: "Business" },
  { value: "entertainment", label: "Entertainment" },
  { value: "life-living", label: "Life & Living" },
  { value: "youth", label: "Youth" },
  { value: "tech-startup", label: "Tech & Startup" },
  { value: "movies-series", label: "Movies & Series" },
  { value: "medical-news", label: "Medical News" },
  { value: "tragedy-news", label: "Tragedy News" },
  { value: "gaming-news", label: "Gaming News" },
  { value: "multimedia", label: "Multimedia" },
  { value: "finance", label: "Finance" },
  { value: "feature-news", label: "Feature News" },
  { value: "international-news", label: "International News" },
  { value: "bangladeshi-news", label: "Bangladeshi News" },
  { value: "breaking-news", label: "Breaking News" },
  { value: "weird-news", label: "Weird News" },
  { value: "travel-living", label: "Travel & Living" },
  { value: "earth", label: "Earth" },
  { value: "law-order", label: "Law & Order" },
  { value: "environment", label: "Environment" },
];

export class Blogs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      blog: {
        thumbnail: "",
        title: "",
        content: "",
        subtitle: "",
        category: "",
        updatedAt: "",
        longContent: "",
        source: "",
        sourceLink: "",
      },
    };
  }

  async componentDidMount() {
    try {
      let { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/blogs/fetch/blog/english/${this.props.params.slug}`
      );

      if (data.success) this.setState({ loading: false, blog: data.blog });

      console.log(this.props.params.category);
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  }

  render() {
    return (
      <div className="blogs">
        {this.props.params.slug && !this.state.loading ? (
          <Helmet>
            <title>{this.state.blog.title}</title>

            <meta
              property="og:url"
              content={`https://tezzfeed.com/${this.state.blog.category}/${this.state.blog.slug}`}
            />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={this.state.blog.title} />
            <meta property="og:description" content={this.state.blog.content} />
            <meta property="og:image" content={this.state.blog.thumbnail} />
          </Helmet>
        ) : null}
        <div className="container">
          {this.state.loading ? (
            <Spinner></Spinner>
          ) : (
            <div className="blog">
              <div className="category">
                {
                  categories.filter(
                    (category) => this.props.params.category === category.value
                  )[0].label
                }
              </div>
              <h1>{this.state.blog.title}</h1>
              {this.state.blog.subtitle ? (
                <p className="subheading">{this.state.blog.subtitle}</p>
              ) : null}
              <div
                className="thumbnail"
                style={{ backgroundImage: `url(${this.state.blog.thumbnail})` }}
              ></div>
              <p className="date">
                {dateFormat(
                  this.state.blog.updatedAt,
                  "dddd, mmmm dS, yyyy, h:MM:ss TT"
                )}
              </p>
              {this.state.blog.longContent ? (
                <p
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: this.state.blog.longContent,
                  }}
                />
              ) : (
                <p className="content">{this.state.blog.content}</p>
              )}

              <p className="source">
                read more at{" "}
                <a
                  href={this.state.blog.sourceLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {this.state.blog.source}
                </a>
              </p>

              <div className="d-flex align-items-center gap-3 my-5">
                <FacebookShareButton url={window.location.href}>
                  <FaFacebook size="2em" />
                </FacebookShareButton>
                <TwitterShareButton url={window.location.href}>
                  <FaXTwitter size="2em" />
                </TwitterShareButton>
                <LinkedinShareButton url={window.location.href}>
                  <FaLinkedin size="2em" />
                </LinkedinShareButton>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(Blogs);
