import React, { Component } from "react";
import axios from "axios";
import dateFormat from "dateformat";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

import Spinner from "../components/spinner";

import { HiArrowSmLeft, HiArrowSmRight } from "react-icons/hi";

import "swiper/css";
import "./home.scss";

export class Home extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      latestNews: [],
      editorsPick: [],
      count: 0,
      topics: [
        { name: "Business", category: "business" },
        { name: "Tech & Startup", category: "tech-startup" },
        { name: "Finance", category: "finance" },
        { name: "Youth", category: "youth" },
        { name: "Sports", category: "sports" },
      ],
      email: "",
      selectedTopic: "business",
      topicBlog: [],
      topicLoading: true,
    };

    this.swiperRef = React.createRef();
    this.subscribeNewsletter = this.subscribeNewsletter.bind(this);
  }

  async componentDidMount() {
    try {
      let { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/blogs`
      );

      if (data.success)
        this.setState({
          loading: false,
          latestNews: data.blogs.latestNews,
          editorsPick: data.blogs.editorsPick,
          topicBlog: data.blogs.business,
          topicLoading: false,
        });
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  }

  async getTopicBlog(category) {
    this.setState({ topicLoading: true, selectedTopic: category });

    try {
      let { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/blogs/category/english/${category}/0`
      );

      if (data.success) {
        this.setState({ topicBlog: data.blogs, topicLoading: false });
      }
    } catch (error) {
      console.error(error);
      this.setState({ topicLoading: false });
    }
  }

  async subscribeNewsletter(event) {
    event.preventDefault();

    let { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/email`,
      { email: this.state.email }
    );

    if (data.success)
      return alert(
        "Your email has been successfully listed on our newsletter! Thank you :D"
      );
    else return alert(data.data);
  }

  render() {
    return (
      <div className="home">
        <Helmet>
          <meta
            name="description"
            content="TezzFeed is a community of creators driven news & entertainment network. Our goal is to connect creators with the right audience and the audience with content they love more. "
          ></meta>
          <title>Tezzfeed</title>
        </Helmet>
        <div className="container-fluid">
          <div className="text-center landing py-5">
            <h1 className="mb-2">
              Stay Informed in Just 100 Words
              <br /> – Quick, Concise, and Curated News for{" "}
              <span>Busy Readers!</span>
            </h1>
            <p>
              <span>Tezzfeed</span> delivers quick and insightful updates about
              the world every day, covering everything <br /> from national news
              to technology and entertainment.
            </p>

            <form
              className="newsletter mx-auto gap-3 my-5"
              onSubmit={this.subscribeNewsletter}
            >
              <input
                className="form-control"
                type="email"
                placeholder="Enter email address"
                required={true}
                value={this.state.email}
                onChange={(event) =>
                  this.setState({ email: event.target.value })
                }
              />
              <button className="button" type="submit">
                Submit
              </button>
            </form>
          </div>

          <div className="latest-news">
            <div className="mx-4 border-bottom py-3 d-flex justify-content-between align-items-center">
              <h1>
                What's <span>Buzzing</span> Right Now – The Latest News at a
                Glance!
              </h1>
              <div className="d-flex gap-2">
                <HiArrowSmLeft
                  size="3em"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.swiperRef.current?.slidePrev()}
                />
                <HiArrowSmRight
                  size="3em"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.swiperRef.current?.slideNext()}
                />
              </div>
            </div>

            {this.state.loading ? (
              <Spinner />
            ) : (
              <Swiper
                spaceBetween={30}
                onBeforeInit={(swiper) => {
                  this.swiperRef.current = swiper;
                }}
                breakpoints={{
                  768: {
                    slidesPerView: 1,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: true,
                }}
                modules={[Autoplay]}
                slidesPerGroupAuto={true}
                className="mySwiper p-3"
              >
                {this.state.latestNews.map((blog, index) => (
                  <SwiperSlide key={index}>
                    <div
                      className="latest-news-card my-4"
                      onClick={() =>
                        (window.location.href = `/${blog.category[0]}/${blog.slug}`)
                      }
                    >
                      <div className="thumbnail-container">
                        <div
                          className="h-50 thumbnail"
                          style={{
                            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(247, 150,64, 0.3)), url(${blog.thumbnail})`,
                          }}
                        ></div>
                      </div>
                      <div className="h-50 content">
                        <h5>
                          {blog.title}
                          <p className="date">
                            {dateFormat(
                              blog.updatedAt,
                              "dddd, mmmm dS, yyyy, h:MM:ss TT"
                            )}
                          </p>
                        </h5>
                        <p>{blog.content.substring(0, 600)}...</p>
                        <p className="source">
                          read more at{" "}
                          <a
                            href={blog.sourceLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {blog.source}
                          </a>
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}

            <div className="text-center my-4">
              <button
                className="button"
                onClick={() => (window.location.href = "/latest-news")}
              >
                Read More
              </button>
            </div>
          </div>

          <div className="editors-pick mb-5 pb-5">
            <h1 className="mx-4 border-bottom py-3">Editor's pick</h1>
            {this.state.loading ? (
              <Spinner />
            ) : (
              <div className="row px-3">
                {this.state.editorsPick.map((blog, index) => (
                  <div className="col-md-4 mb-4">
                    <div
                      className="latest-news-card my-4"
                      onClick={() =>
                        (window.location.href = `/${blog.category[0]}/${blog.slug}`)
                      }
                    >
                      <div className="thumbnail-container">
                        <div
                          className="h-50 thumbnail"
                          style={{
                            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(247, 150,64, 0.3)), url(${blog.thumbnail})`,
                          }}
                        ></div>
                      </div>
                      <div className="h-50 content">
                        <h5>
                          {blog.title}
                          <p className="date">
                            {dateFormat(
                              blog.updatedAt,
                              "dddd, mmmm dS, yyyy, h:MM:ss TT"
                            )}
                          </p>
                        </h5>
                        <p>{blog.content.substring(0, 600)}... </p>
                        <p className="source">
                          read more at{" "}
                          <a
                            href={blog.sourceLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {blog.source}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div className="text-center my-4">
              <button
                className="button"
                onClick={() => (window.location.href = "/editors-pick")}
              >
                Read More
              </button>
            </div>
          </div>

          <div className="topics mb-5 pb-4">
            <h1 className="mx-4 border-bottom py-3">Topics</h1>
            <div className="container">
              <ul className="topic-nav">
                {this.state.topics.map((topic, index) => (
                  <li
                    key={index}
                    className={
                      this.state.selectedTopic === topic.category
                        ? "selected"
                        : ""
                    }
                    onClick={() => this.getTopicBlog(topic.category)}
                  >
                    {topic.name}
                  </li>
                ))}
              </ul>
            </div>

            {this.state.topicLoading ? (
              <Spinner />
            ) : (
              <div className="row px-3">
                {this.state.topicBlog.slice(0, 3).map((blog, index) => (
                  <div className="col-md-4 mb-4">
                    <div
                      className="latest-news-card my-4"
                      onClick={() =>
                        (window.location.href = `/${blog.category[0]}/${blog.slug}`)
                      }
                    >
                      <div className="thumbnail-container">
                        <div
                          className="h-50 thumbnail"
                          style={{
                            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(247, 150,64, 0.3)), url(${blog.thumbnail})`,
                          }}
                        ></div>
                      </div>
                      <div className="h-50 content">
                        <h5>
                          {blog.title}
                          <p className="date">
                            {dateFormat(
                              blog.updatedAt,
                              "dddd, mmmm dS, yyyy, h:MM:ss TT"
                            )}
                          </p>
                        </h5>
                        <p>{blog.content.substring(0, 600)}... </p>
                        <p className="source">
                          read more at{" "}
                          <a
                            href={blog.sourceLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {blog.source}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div className="text-center my-4">
              <button
                className="button"
                onClick={() =>
                  (window.location.href = `/${this.state.selectedTopic}`)
                }
              >
                Read More
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
