import React, { Component } from "react";
import axios from "axios";
import dateFormat from "dateformat";
import { Helmet } from "react-helmet";

import Spinner from "../components/spinner";

import { withRouter } from "../controllers/withRouter";

import "./home.scss";

export class Category extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      blogs: [],
      count: 0,
    };
  }

  async componentDidMount() {
    try {
      let { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/blogs/category/english/${this.props.params.category}/${this.state.count}`
      );

      if (data.success) this.setState({ loading: false, blogs: data.blogs });
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  }

  async getBlogs(count) {
    this.setState({ loading: true });

    try {
      let { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/blogs/category/english/${this.props.params.category}/${count}`
      );

      if (data.success) {
        for (let i = 0; i < data.blogs.length; i++) {
          this.setState((prevState) => ({
            blogs: [...prevState.blogs, data.blogs[i]],
          }));
        }
        this.setState({ loading: false });
      }
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  }

  render() {
    return (
      <div className="blogs">
        <div className="container">
          <div>
            {this.props.params.slug && !this.state.loading ? (
              <Helmet>
                <title>{this.state.blogs[0].title}</title>

                <meta
                  property="og:url"
                  content={`https://tezzfeed.com/${this.state.blogs[0].category}/${this.state.blogs[0].slug}`}
                />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={this.state.blogs[0].title} />
                <meta
                  property="og:description"
                  content={this.state.blogs[0].content}
                />
                <meta
                  property="og:image"
                  content={this.state.blogs[0].thumbnail}
                />
              </Helmet>
            ) : null}
            {this.state.blogs.map((blog, index) => (
              <div className="news-card my-4" key={index}>
                <div className="row">
                  <div className="col-xl-4 thumbnail-container">
                    <div
                      className="thumbnail"
                      style={{ backgroundImage: `url(${blog.thumbnail})` }}
                    ></div>
                  </div>
                  <div className="col-xl-8">
                    <div className="d-flex justify-content-between h-auto flex-column">
                      <div>
                        <h4
                          className="title"
                          onClick={() =>
                            (window.location.href = `/${blog.category[0]}/${blog.slug}`)
                          }
                        >
                          {blog.title}
                        </h4>
                        <p className="date">
                          {dateFormat(
                            blog.updatedAt,
                            "dddd, mmmm dS, yyyy, h:MM:ss TT"
                          )}
                        </p>
                        <p className="content">{blog.content}</p>
                      </div>

                      <p className="source">
                        read more at{" "}
                        <a
                          href={blog.sourceLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {blog.source}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {this.state.loading ? null : this.state.blogs.length <= 0 ? (
            <h5 className="fw-bold text-center text-muted py-4">No blogs</h5>
          ) : (
            <div className="text-center">
              <button
                className="mt-3 mb-4 button"
                onClick={() => {
                  this.setState({ count: this.state.count + 1 }, () =>
                    this.getBlogs(this.state.count)
                  );
                }}
              >
                Load More
              </button>
            </div>
          )}
          {this.state.loading ? <Spinner></Spinner> : null}
        </div>
      </div>
    );
  }
}

export default withRouter(Category);
